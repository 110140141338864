import jQuery from "jquery";
import {gsap} from "gsap";

if (document.body.classList.contains('waiting')) {



    window.addEventListener("load", () => {

        var wtl = new gsap.timeline();
        wtl
            .to('.wating-top__logo',{
                duration: .5,
                delay: .5,
                opacity: 1,
                y: 32
            })
            .add('step1')
            .to('.wating-top__logo',{
                duration: .5,
                y: 0
            },'step1+=0.5')
            .to('.wating-top__logo path',{
                duration: .5,
                fill: '#FFF'
            },'step1+=0.5')
            .to('.wating-top__wrap',{
                duration: .5,
                opacity: 1,
            },'step1+=0.5')
            .to('.wating-top__content-title',{
                duration: .5,
                opacity: 1,
                y: 0
            },'step1+=0.5')
            .to('.wating-top__content-cta',{
                duration: .5,
                opacity: 1,
                y: 0
            },'step1+=0.5')
            .to('.wating-top__content-bottom',{
                duration: .5,
                opacity: 1,
            },'step1+=0.5')
            .add(function(){
                $('body').removeClass('lock');
            },'step1+=0.5');
    });


    jQuery(document).ready(function ($) {

        jQuery('.wating-top__content-cta').click(function () {
            gsap.to(window, {duration: 1, scrollTo:"#acquista", ease: "power2.inOut"});
        })

        jQuery('.myCheckbox span').click(function(){

            if (jQuery(this).find('svg').hasClass('checked')) {
                jQuery(this).find('svg').removeClass('checked');
                jQuery('.myCheckbox').find('input').prop('checked', false);
            } else {
                jQuery(this).find('svg').addClass('checked');
                jQuery('.myCheckbox').find('input').prop('checked', true);
            }

        });


    });

}