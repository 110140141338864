import jQuery from "jquery";
import {gsap} from "gsap";
import { SplitText } from "gsap/SplitText"

if (document.body.classList.contains('homepage')) {

    jQuery(document).ready(function ($) {


        var wineSlider = $('.home-slider').slick({
            arrows: false,
            dots: false,
            autoplay: true,
            autoplaySpeed: 5000,
            fade: true,
            cssEase: 'linear',
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
        });


        let title = new SplitText('.home-hero__item-text', {type: "words,chars"});


        jQuery('.home-top__logo').click(function () {
            console.log('test');
        });


        jQuery('.home-hero').click(function () {




            var herotl = new gsap.timeline();

            herotl
                .add('start')
                .to('.home-hero__item--current .home-hero__item-text div', {

                    webkitFilter:"blur(" + 6 + "px)",
                    y: -40,
                    opacity: 0,
                    stagger: {
                        each: 0.0125,
                        from: "random"
                    }
                })
                .to('.home-hero__item--current',{
                    duration: 1.5,
                    delay: .5,
                    opacity: 0,
                    scale: 1.2,
                    webkitFilter:"blur(" + 6 + "px)",
                    clearProps: "all"
                },'start')
                .to('.home-hero__item--next',{
                    duration: 0.5,
                    webkitFilter:"blur(" + 0 + "px)",
                    clearProps: "all"
                },'-=0.5')
                .add(function () {


                    var currentIDX = parseInt($('.home-hero').data('index'));

                    if (currentIDX < $('.home-hero__data-item').length - 1) {
                        currentIDX++;
                    } else {
                        currentIDX = 1;
                    }





                    var curEL = $('.home-hero__item--current');
                    var nextEL = $('.home-hero__item--next');
                    $(nextEL).removeClass('home-hero__item--next').addClass('home-hero__item--current');
                    $(curEL).removeClass('home-hero__item--current').addClass('home-hero__item--next');


                    if (currentIDX < $('.home-hero__data-item').length - 1) {

                        var nextSL = $('.home-hero__data-item').eq(currentIDX+1).html();
                        $('.home-hero__item--next').html(nextSL);
                        $('.home-hero').data('index',currentIDX);


                    } else {

                        var nextSL = $('.home-hero__data-item').eq(0).html();
                        $('.home-hero__item--next').html(nextSL);
                        $('.home-hero').data('index',-1);

                    }


                });


        });

    });

}