//Style
import '../scss/style.scss';
import 'slick-carousel/slick/slick.scss';

//Javascript
import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;

import 'bootstrap';
import { gsap } from "gsap";
import LocomotiveScroll from 'locomotive-scroll';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
//import {ScrollTrigger} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollToPlugin);
import slick from "slick-carousel/slick/slick";
var Isotope = require('isotope-layout');
var imagesLoaded = require('imagesloaded');

require('./waiting.js');
require('./home.js');
require('./fam.js');

function concatValues( obj ) {
    var value = '';
    for ( var prop in obj ) {
        value += obj[ prop ];
    }
    return value;
}
var buttonFilters = {};
var buttonFilter = '*';
var $news;

window.addEventListener("resize", () => {
    //resize
});

window.addEventListener("load", () => {
    setTimeout(function () {
        jQuery('body').addClass('loaded');
    },250)

    window.ss = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true
    });

    window.ss.on('call', triggerResize => {

        window.dispatchEvent(new Event('resize'));

    });

    jQuery('.site__menu-toggle').click(function(){
        jQuery('.site__menu').addClass('on');
    });
    jQuery('.site__menu-x').click(function(){
        jQuery('.site__menu').removeClass('on');
    });

    if (document.querySelectorAll('.terr-sbox__col-wrap').length > 0) {
        $news = new Isotope( '.terr-sbox__col-wrap', {
            itemSelector: '.terr-sbox__col',
            filter: function() {
                var $this = $(this);
                return $this.is( buttonFilter );
            }
        });

        $('.terr-news__lm').on( 'click', function() {
            // create new item elements

            var currentpage = parseInt($('.terr-news__currentpage').data('page'));
            if ($('.terr-news__pag--'+currentpage).hasClass('terr-news__pag--last')) {
                $('.terr-news__lm').remove();
            } else {
                $('.terr-news__lm').css('opacity',0);
            }
            var $items = $('.terr-news__pag--'+currentpage).find('.terr-sbox__col');

            var images = [];
            $items.each(function () {
                var src = $(this).find('img').data('src');
                images.push(src);
                $(this).find('img').attr('src',src);
            })


            const preloadImages = new Promise((resolve, reject) => {
                imagesLoaded(images, {}, resolve);
            });

            let allDone = [preloadImages];

            Promise.all(allDone).then(()=>{

                $('.terr-sbox__col-wrap').append( $items );
                $news.appended( $items );

                currentpage++;
                $('.terr-news__currentpage').data('page',currentpage);

                setTimeout(function () {
                    window.dispatchEvent(new Event('resize'));
                    $news.layout();
                    $('.terr-news__lm').css('opacity',1);
                },250)


            });

        });

        $('.terr-news__tag').on('click',function () {

            if ($(this).hasClass('active')) {

                $(this).removeClass('active');

                var tg = $(this).data('tag');
                delete buttonFilters[tg]
                buttonFilter = concatValues( buttonFilters ) || '*';
                $news.arrange();

                if ($news.filteredItems.length == 0) {
                    $('#noResultsContainer').fadeIn();
                } else  {
                    $('#noResultsContainer').fadeOut(1);
                }

                window.dispatchEvent(new Event('resize'));


            } else {

                $(this).addClass('active');

                var filterGroup = $(this).attr('data-tag');
                buttonFilters[ filterGroup ] = '.'+filterGroup;
                buttonFilter = concatValues( buttonFilters ) || '*';
                $news.arrange();

                if ($news.filteredItems.length == 0) {
                    $('#noResultsContainer').fadeIn();
                } else  {
                    $('#noResultsContainer').fadeOut(1);
                }

            }

            window.dispatchEvent(new Event('resize'));
        });
    }


});