import jQuery from "jquery";
import {gsap} from "gsap";
import Splide from '@splidejs/splide';

if (document.body.classList.contains('family')) {

    jQuery(document).ready(function ($) {

        new Splide( '.splide', {
            arrows: false,
            type   : 'loop',
            padding: '20%',
            breakpoints: {
                992: {
                    padding: '0',
                },
            }
        } ).mount();

    });

}